import React from "react";
import { Typography } from "@mui/material";
import { compressImage } from "./Compression";

function UploadAndDisplayImage() {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [etat, setEta] = React.useState(false);

  const RemoveImage = () => {
    setSelectedImage(null);
    setEta(false);
  };

  const selectImage = async (event) => {
    const image = event.target.files[0];
    const compressedFile = await compressImage(image, 500);
    setSelectedImage(compressedFile);
    setEta(true);
    console.log("compresson:)",compressedFile)
  };

  const UploadImage = () => {
    return (
      <div>
        {selectedImage && (
          <div>
            <img
              alt="not fount"
              style={{
                width: 100,
                height: 100,
                borderRadius: 100,
              }}
              src={URL.createObjectURL(selectedImage)}
            />

            <br />
            <button onClick={() => RemoveImage()}>Supprimer L'Image</button>
          </div>
        )}
        {etat === false ? (
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              selectImage(event);
            }}
            style={{ marginTop: -50 }}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return {
    selectedImage,
    UploadImage,
  };
}

export default UploadAndDisplayImage;
