import imageCompression from "browser-image-compression";

export const compressImage = async (
  file,
  maxSizeKB = 1024,
  maxWidthOrHeight = 800
) => {
  const options = {
    maxSizeMB: maxSizeKB / 1024, // Convertir KB en MB
    maxWidthOrHeight: maxWidthOrHeight, // Limiter la largeur/hauteur
    useWebWorker: true,
  };

  try {
    let compressedFile = await imageCompression(file, options);

    // Vérification de la taille du fichier compressé
    const fileSizeKB = compressedFile.size / 1024; // Taille en KB
    if (fileSizeKB > maxSizeKB) {
      // Si la taille dépasse la limite, relancer la compression avec une qualité plus faible
      return compressImage(compressedFile, maxSizeKB, maxWidthOrHeight);
    }

    return compressedFile;
  } catch (error) {
    console.error("Erreur lors de la compression de l'image :", error);
    throw error;
  }
};
