import React from "react";
import { request } from "../../../../controller/Request";
import { useState } from "react";
import useGetEntreprise from "./useGetEntreprise";

function useActionEntreprise() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getData } = useGetEntreprise({ url: "equipe/all" });

  const Save = ({ data = null, selectedImage = null }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("rccm", data.rccm);
    formd.append("idnational", data.idnational);
    formd.append("file", selectedImage);
    formd.append("designation", data.designation);
    formd.append("description", data.description);
    formd.append("slogan", data.slogan);
    formd.append("adresse", data.adresse);
    formd.append("phone1", data.phone1);
    formd.append("phone2", data.phone2);
    formd.append("email", data.email);
    request
      .post("entreprise/save", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        console.log("error:)", error);
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };
  const Update = ({
    data = null,
    selectedImage = null,
    identreprise = null,
  }) => {
    setLoading(true);
    const formd = new FormData();
    formd.append("rccm", data.rccm);
    formd.append("idnational", data.idnational);
    formd.append("file", selectedImage);
    formd.append("designation", data.designation);
    formd.append("description", data.description);
    formd.append("slogan", data.slogan);
    formd.append("adresse", data.adresse);
    formd.append("phone1", data.phone1);
    formd.append("phone2", data.phone2);
    formd.append("email", data.email);
    formd.append("identreprise", identreprise);

    request
      .put("entreprise/update", formd)
      .then((e) => {
        setMessage(e.data.message);
        setLoading(false);
        getData();
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error.response.data.message);
        }
      });
  };

  const ActiveUtilisateur = ({
    idequipes = null,
    setAnnuller,
    openCollapse,
  }) => {
    setLoading(true);
    const data = idequipes;
    request
      .put(`equipe/desableoractivable1/${data}`)
      .then((e) => {
        getData();
        setMessage(e.data.message);
        setLoading(false);
        setAnnuller(!openCollapse);
      })
      .catch((error) => {
        if (error.code) {
          setLoading(false);
          setMessage(error.message);
        } else {
          setLoading(false);
          setMessage(error?.response?.data.message);
        }
      });
  };

  return {
    message: message,
    loading: loading,
    save: Save,
    update: Update,
    setMessage: setMessage,
    setLoading: setLoading,
    ActiveUtilisateur: ActiveUtilisateur,
  };
}

export default useActionEntreprise;
