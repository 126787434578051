import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { request, urlbase2 } from "../controller/Request";
import imgheaders from "../img/code.png";

import { useNavigate } from "react-router-dom";
// import Montserrat from "@fontsource/montserrat";

export const Header = () => {
  const [header, setHeader] = useState("");
  const nav = useNavigate();
  const getProprieteEntreprise = () => {
    request
      .get("entreprise")
      .then((e) => {
        setHeader(e.data.data);
        localStorage.setItem("title", e.data.data.designation);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    localStorage.setItem("isAuth", false);
  }, []);

  useEffect(() => {
    getProprieteEntreprise();
  }, []);
  return (
    <div data-aos="zoom-in-up">
      <Grid
        container
        style={{
          background: "#fff",
        }}
        display={{
          md: "none",
          lg: "none",
          // sm: "none",
        }}>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          style={{
            background: "#fff",
          }}
          // display={{
          //   md: "none",
          //   sm: "none",
          //   xs: "block",
          // }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              mt: "17%",
              fontSize: 35,
              ml: 3,
              fontWeight: "bold",
              color: "#1C2E58",
              // mt: 2,
            }}>
            {header.designation}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              mt: 1,
              fontSize: 20,
              ml: 3,
              fontWeight: "bold",
              color: "#000",
              textAlign: "start",
            }}>
            {header.description}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              mt: 1,
              fontSize: 16,
              ml: 3,
              color: "#000",
              textAlign: "start",
              display: "-webkit-box",
              WebkitLineClamp: 5, // Limite à 4 lignes
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto",
            }}>
            {header.slogan}
          </Typography>
          <Stack
            direction={{
              xs: "column",
              md: "row",
              sm: "row",
            }}
            sx={{
              ml: 3,
              mt: 5,
            }}>
            {/*
             <Button
              sx={{
                bgcolor: "#1C2E58",
                height: "20%",
                fontSize: 16,
                color: "white",
                marginBottom: 5,
                marginRight: 2,
                fontFamily: "Montserrat",
              }}
            >
              NOS SERVICES
            </Button>
            <Button
              sx={{
                height: "20%",
                fontSize: 16,
                marginBottom: 5,
                fontFamily: "Montserrat",
                width: "95%",
              }}
              variant="text"
              onClick={()=>nav("/contact")}
            >
              NOUS CONTACTER
            </Button>
            */}
          </Stack>
        </Grid>
        <Stack
          flexDirection={"row"}
          mt={-3}
          mb={3}
          ml={3}
          justifyContent={"end"}
          alignItems={"center"}
          bgcolor={"#fff"}
          // mr={1}
        >
          <Button
            onClick={() => nav("gallery")}
            variant="outlined"
            sx={{
              borderColor: "#1C2E58",
              borderWidth: 1,
              color: "#1C2E58",
              marginRight: 2,
              // fontWeight: "bold",
              fontFamily: "Montserrat",
              fontSize: 14,
            }}>
            Nos Images
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#1C2E58",
              color: "#FFF",
              fontFamily: "Montserrat",
              fontSize: 14,
              mr: 2,
            }}
            onClick={() => nav("/contact")}>
            Nous Contacter
          </Button>
        </Stack>
        <div style={{ marginTop: 10 }}>
          <img
            src={`${urlbase2}/${header.logoentreprise}`}
            alt="Hub Technologie"
            style={{
              width: 350,
              height: 350,
              marginTop: "-10%",
              objectFit: "cover",
              // marginLeft: "-23%",
              // marginBottom: "-30%",
            }}
          />
        </div>
      </Grid>
      {
        //web
      }

      <div>
        <Grid
          container
          display={{
            xs: "none",
            md: "flex",
            sm: "flex",
            lg: "flex",
            xl: "flex",
          }}
          flexDirection={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          bgcolor={"#fff"}
          // mt={-4}
        >
          <Stack
            flexDirection={"row"}
            mt={10}
            justifyContent={"end"}
            alignItems={"center"}
            bgcolor={"#fff"}
            // mr={1}
          >
            <Button
              onClick={() => nav("gallery")}
              variant="outlined"
              sx={{
                borderColor: "#1C2E58",
                borderWidth: 1,
                color: "#1C2E58",
                marginRight: 2,
                // fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: 14,
              }}>
              Nos Images
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#1C2E58",
                color: "#FFF",
                fontFamily: "Montserrat",
                fontSize: 14,
                mr: 2,
              }}
              onClick={() => nav("/contact")}>
              Nous Contacter
            </Button>
          </Stack>
        </Grid>

        <Grid
          container
          display={{
            xs: "none",
            md: "flex",
            sm: "flex",
            lg: "flex",
            xl: "flex",
          }}
          height={"90%"}
          bgcolor={"#fff"}
          mt={-4}>
          <Grid item xs={12} sm={5} lg={6} md={6}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                mt: "8%",
                fontSize: 85,
                ml: 10,
                fontWeight: "bold",
                color: "#1C2E58",
                // mt: 2,
              }}>
              {header.designation}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                mt: 1,
                fontSize: 30,
                ml: 10,
                fontWeight: "bold",
                color: "#000",
                textAlign: "start",
              }}>
              {header.description}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                mt: 1,
                fontSize: 14,
                ml: 10,
                // fontWeight: "bold",
                color: "#000",
                textAlign: "justify",
              }}>
              {header.slogan}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                mt: 1,
                fontSize: 14,
                ml: 10,
                // fontWeight: "bold",
                color: "gray",
                textAlign: "justify",
              }}>
              Simplifiez votre vie grâce à l'informatique 😀
            </Typography>
            <Stack
              direction={{
                xs: "column",
                md: "row",
                sm: "row",
              }}
              sx={{
                ml: 10,
                mt: 2,
              }}>
              <Button
                sx={{
                  bgcolor: "#1C2E58",
                  height: "20%",
                  fontSize: 16,
                  color: "white",
                  marginBottom: 5,
                  marginRight: 2,
                  fontFamily: "Montserrat",
                  ":hover": {
                    bgcolor: "#1C2E58",
                  },
                }}
                onClick={() => nav("/gallery")}>
                NOS IMAGES
              </Button>
              <Button
                sx={{
                  height: "20%",
                  fontSize: 16,
                  marginBottom: 5,
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  ":hover": {
                    background: "none",
                    textDecoration: "underline",
                  },
                }}
                variant="text"
                onClick={() => nav("/contact")}>
                NOUS CONTACTER
              </Button>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={
              {
                // mt: 20,
                // ml: 10,
              }
            }>
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              // bgcolor={"red"}
              mt={5}
              ml={5}
              // width={"150%"}
              >
              <img
                src={`${urlbase2}/${header.logoentreprise}`}
                alt="Hub Technologie"
                style={{
                  width: "100%",
                  height: "auto",
                  // resize: "contain",
                  // marginTop: "-10%",
                  paddingBottom: "5%",
                  objectFit: "contain",
                  // marginRight: -100,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
