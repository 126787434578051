import axios from "axios";

const urlbase = process.env.REACT_APP_API_URL_CLOUD;
const urlbase2 = process.env.REACT_APP_API_URL_CLOUD;

const token = localStorage.getItem("token");

const request = axios.create({
  baseURL: urlbase,
  timeout: 5000,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});

const requests = axios.create({
  baseURL: urlbase,
  timeout: 5000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export { urlbase2, request, requests };
